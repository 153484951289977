@mixin scrollbars($size: 6px, $foreground-color: $elantis-blue, $background-color: transparent) {
  // sass-lint:disable-block no-vendor-prefixes
  // For Google Chrome
  &::-webkit-scrollbar {
    position: absolute;
    width: $size;
    height: $size;
  }

  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
    border-radius: $size;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
  }

  // For Internet Explorer
  body {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}

@mixin center-x {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

@mixin center-y {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@mixin center-xy {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin get-all-space {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@mixin aspect-ratio($width, $height) {
  position: relative;

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }

  > * {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

@mixin image-fit($fit: cover, $position: null) {
  @include get-all-space;

  object-fit: $fit;

  @if $position {
    object-position: $position;
  }
}

@mixin owl($margin: false) {
  > :first-child {
    margin-top: 0;

    @if $margin {
      margin-bottom: $margin;
    }
  }

  > :last-child {
    margin-bottom: 0;
  }

  > * + * {
    margin-top: 0;

    @if $margin {
      margin-bottom: $margin;
    }
  }
}

@mixin fluid-type($from, $to, $until: xxl) {
  font-size: calc(
    #{$from}px + (#{$to} - #{$from}) *
      (
        (100vw - #{mq-get-breakpoint-width('xxs')}) /
          (#{strip-unit(mq-get-breakpoint-width($until))} - #{strip-unit(mq-get-breakpoint-width(xxs))})
      )
  );

  @include mq(xxl) {
    font-size: #{$to}px;
  }
}

@mixin fluid($property, $map) {
  $finalmap: ();

  @each $breakpoint, $value in $map {
    $nameToPx: map-get($mq-breakpoints, $breakpoint);

    @if $nameToPx {
      $finalmap: map-merge(
        $finalmap,
        (
          $nameToPx: $value,
        )
      );
    } @else {
      $finalmap: map-merge(
        $finalmap,
        (
          $breakpoint: $value,
        )
      );
    }
  }

  @include poly-fluid-sizing($property, $finalmap);
}

@mixin gradient-lines {
  &::before {
    content: '';
    display: block;
    height: 13.5rem;
    background: linear-gradient(
      to top,
      $c-yellow 50%,
      $c-main-tertiary 50%,
      $c-main-tertiary 80%,
      $c-main-quaternary 80%,
      $c-main-quaternary 100%
    );
  }
}

@mixin loading($color: $c-black) {
  background: linear-gradient(
    to right,
    $color 0%,
    $color 50%,
    lighten($color, 10) 50%,
    lighten($color, 10) 70%,
    $color 70%,
    $color 100%
  );
  background-size: 200%;
  background-position-x: 200%;
  border: 0;

  &:hover {
    cursor: pointer;
  }

  &.is-loading {
    pointer-events: none;
    color: rgba($c-white, 0.5);
    opacity: 0.8;
    animation: loading-mixin 0.8s linear infinite;
  }
}

@keyframes loading-mixin {
  to {
    background-position-x: 0%;
  }
}
