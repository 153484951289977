
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        




































/* stylelint-disable declaration-no-important */
/* stylelint-disable property-no-vendor-prefix */
.input {
  position: relative;
  display: flex;
  align-items: center;
  color: $c-black;
  border-bottom: 2px solid $tundora;
  transition: border-color 0.15s;

  &::-ms-clear {
    display: none;
  }

  &::-webkit-search-cancel-button {
    -webkit-appearance: none;
  }

  &.is-editable {
    cursor: pointer;
  }
}

.input-inner {
  flex: 1;

  .input.inline & {
    display: flex;
    align-items: center;
    gap: 2rem;
  }
}

.label {
  display: block;
  flex-shrink: 0;
  margin-bottom: 0.5rem;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1;
  text-align: left;
  text-transform: uppercase;
  letter-spacing: 0.6px;
}

input {
  width: 100%;
  padding: 0.5rem 0 0.3rem;
  font-size: 1.8rem;
  background: transparent;
  border: 0;

  .is-disabled & {
    pointer-events: none;
  }

  &::placeholder {
    color: $c-gray-medium;
  }

  /* Change the white to any color */
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px $c-white inset !important;
  }

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }
}

.input__edit {
  @extend %focusable;

  display: flex;
  align-items: center;
  margin-left: auto;
  padding: 0;
  background-color: transparent;
  border: 0;
  cursor: pointer;

  svg {
    width: 1.8rem;
    height: 1.8rem;
    transition: transform 0.15s;
  }

  &:hover {
    svg {
      transform: scale(1.1);
    }
  }
}
