
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        




















































































































.gdpr,
[class*='gdpr--'] {
  // prettier-ignore
  @include fluid(padding,(xxs: 20px, xxl: 50px));

  display: flex;
  flex-direction: column;
  gap: 7rem;
  width: 95%;
  max-width: 90rem;
  background-color: $c-white;
}

.gdpr__header,
.gdpr__body {
  // prettier-ignore
  @include fluid(gap,(xxs: 20px, xxl: 40px));

  display: flex;
  flex-direction: column;
}

.gdpr__input {
  &.input {
    border-color: $elantis-grey-light;
  }

  &.is-editable {
    border-color: $tundora;
  }
}

.gdpr__body__title {
  color: $c-dark;
}

.gdpr__body__feeedback {
  align-self: flex-end;
}

.gdpr__submit {
  align-self: flex-end;
}
