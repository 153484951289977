
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        
























































.legal,
[class*='legal--'] {
  // prettier-ignore
  @include fluid(margin-left,(xxs: 20px, xxl: 90px));

  // prettier-ignore
  @include fluid(gap,(xxs: 40px, xxl: 70px));

  display: flex;
  flex-direction: column;
  max-width: 81rem;
  padding: 7rem 0;

  hr {
    height: 1px;
    margin: 0;
    background: $elantis-grey-light;
    border: 0;
  }
}

.legal__back {
  @extend %fw-medium;

  display: flex;
  align-items: center;
  gap: 1rem;
  color: $elantis-blue;
  font-size: 1.5rem;
  text-decoration: none;
  text-transform: uppercase;

  &:hover {
    text-decoration: underline;
  }

  ::v-deep svg {
    margin-bottom: 0.2rem;
  }
}

.legal__form {
  padding: 0;
}

.legal__copy {
  display: flex;
  flex-direction: column;
  gap: 4rem;

  ::v-deep {
    p {
      margin: 0;
      padding: 0;
      font-size: 1.8rem;
      font-weight: 400;
      line-height: 2.5rem;
    }

    .gdpr__image {
      display: none;
    }

    .gdpr__question {
      display: none;
      padding: 0;
      border: 0;
    }

    .gdpr__question.is-partial {
      display: block;
    }

    .gdpr__question__title {
      margin-bottom: 4rem;
      padding: 0;
      color: $elantis-blue;
      font-size: 2.5rem;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      pointer-events: none;

      svg {
        display: none;
      }
    }

    .gdpr__question__reply {
      height: auto;
    }

    .gdpr__intro,
    .gdpr__question__reply__content {
      display: flex;
      flex-direction: column;
      gap: 2rem;
    }
  }
}
