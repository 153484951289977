/**
* Dev, based on $env (from gulp)
*/
$env: 'development' !default;

/**
 * Colors
 * for the naming use http://chir.ag/projects/name-that-color/
 * from darker to lighter ?
 */

// Global colors
$c-black: rgb(0, 0, 0);
$c-gray-darkest: #2d2d2d;
$c-gray-darker: #333;
$c-gray-dark: #858585;
$c-gray-medium: #9e9e9e;
$c-gray: #b2b2b2;
$c-gray-lightest: #f5f5f5;
$c-white: rgb(255, 255, 255);
$c-transparent: transparent;
$c-red: #f00;
$c-green: #17b603;
$c-lemon: #f8e71c;

// Dev colors
$c-dev-bg: #3e4b55;
$c-dev-success: #090;
$c-dev-error: #d90b0b;
$c-dev-warning: #f50;
$c-dev-info: #829deb;
$c-dev-rebecca: rebeccapurple; /* stylelint-disable-line color-named */

// Project colors
$c-gray-light: #d8d8d8;
$tundora: #404040;
$denim: #0b5db7;
$frosted-mint: #e6fff3;
$midnight: #001f41;
$pattens-blue: #d4eaff;
$dusty-gray: #9b9b9b;
$silver: #cdcdcd;
$prussian-blue: #00234a;
$mint-tulip: #cdf5ed;
$energy-yellow: #fad961;
$yellow-orange: #ff9f4a;
$aquamarine: #78ffbe;
$java: #1ecbac;
$elf-green: #097a63;
$international-orange: #ff5009;
$alto: #dfdfdf;
$sunshade: #ffa635;
$silver: #bbb;
$supernova: #fdc609;

$elantis-orange: #fb5d2c;
$elantis-grey-light: #e7e7e7;
$elantis-blue: #0b5db7;
$elantis-turquoise: #36d0b2;

$c-blue-light: #d4eaff;
$c-blue-dark: #00234a;
$c-turquoise-dark: #097a63;
$c-turquoise-medium: #4fdcc0;
$c-turquoise-light: #cdf5ed;
$c-turquoise-lighter: #e6fff3;
$c-yellow-medium: #fad961;
$c-yellow-dark: #ff9f4a;
$c-dark: $tundora;
$c-light: $c-white;

// Color Gradient
/* sass-lint:disable max-line-length */
$cg-yellow: linear-gradient(to right, #{$c-yellow-medium}, #{$c-yellow-dark});
$cg-yellow-blue: linear-gradient(
  to right,
  #{$c-yellow-medium} 0%,
  #{$c-yellow-dark} 50%,
  #{$elantis-blue} 50%,
  #{$elantis-blue} 100%
);
$cg-yellow-turquoise: linear-gradient(
  to right,
  #{$c-yellow-medium} 0%,
  #{$c-yellow-dark} 50%,
  #{$c-turquoise-medium} 50%,
  #{$c-turquoise-medium} 100%
);
$cg-yellow-transparent: linear-gradient(
  to right,
  #{$c-yellow-medium} 0%,
  #{$c-yellow-dark} 50%,
  transparent 50%,
  transparent 100%
);
$cg-red-blue: linear-gradient(
  to right,
  #{$elantis-orange} 0%,
  #{$elantis-orange} 50%,
  #{$elantis-blue} 50%,
  #{$elantis-blue} 100%
);
$cg-red-white: linear-gradient(
  to right,
  #{$elantis-orange} 0%,
  #{$elantis-orange} 50%,
  #{$c-white} 50%,
  #{$c-white} 100%
);
$cg-yellow-red: linear-gradient(
  to right,
  #{$c-yellow-medium} 0%,
  #{$c-yellow-dark} 50%,
  #{$elantis-orange} 50%,
  #{$elantis-orange} 100%
);
/* sass-lint:enable max-line-length */

/*
* Dimensions
*/
$content-width: 1440px;
$header-height: 50px;
$header-height-large: 100px;
$header-breakpoint: xl;

/*
* Typography
*/

$base-font-size: 10px !default;

$ff-default: 'Swiss721BT', sans-serif;
$ff-alt: 'Martel', sans-serif;
$body-text: 1.5rem;
$body-text-m: 1.8rem;

$font-sizes: (
  h1: 3.6rem,
  h2: 2.8rem,
  h3: 2.4rem,
  h4: 1.4rem,
);

/**
* RWD
*/
$mq-responsive: true;
$mq-static-breakpoint: 'large';
