
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        
































.select {
  border-bottom: 0.1rem solid $c-gray-light;

  &.inline {
    display: flex;
    align-items: center;
    gap: 2rem;
  }

  &.has-error {
    border-bottom: 0.1rem solid $c-red;
  }
}

.select-inner {
  position: relative;

  .select.inline & {
    flex: 1;
  }

  svg {
    flex-shrink: 0;
    width: 1.8rem;
    height: 1.8rem;
  }
}

.real-select {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.label {
  display: block;
  flex-shrink: 0;
  margin-bottom: 0.5rem;
  color: $c-black;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1;
  text-align: left;
  text-transform: uppercase;
  letter-spacing: 0.6px;

  .has-error & {
    color: $c-red;
  }
}

.placeholder {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  transition: color 0.15s;

  &:hover {
    color: $c-gray !important;
  }
}

.options {
  position: absolute;
  z-index: 10;
  top: calc(100% + 5px);
  left: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  height: 0;
  padding: 2rem;
  gap: 1rem;
  background: $c-white;
  border-radius: 1rem;
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.1);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s;

  .is-open & {
    height: auto;
    opacity: 1;
    visibility: visible;
  }

  li {
    list-style-type: none;
  }
}

.option {
  display: flex;
  width: 100%;
  padding: 0.8rem;
  font-size: 1.8rem;
  text-decoration: none;
  background: none;
  border: 0;
  cursor: pointer;

  &:not(.placeholder):hover {
    background: $elantis-grey-light;
    border-radius: 0.8rem;
  }
}

.icon {
  width: 1rem;
  height: 0.6rem;
  margin-left: 0.5rem;
  transition: transform 0.2s;

  .is-open & {
    transform: scale(-1);
  }
}
