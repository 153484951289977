
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        
















.textarea {
  position: relative;
}

.label {
  display: block;
  margin-bottom: 1rem;
}

textarea {
  // prettier-ignore
  @include fluid(padding,(xxs: 10px, xxl: 30px));

  width: 100%;
  min-height: 10rem;
  font-size: 1.8rem;
  border: 0.1rem solid $c-gray-medium;
  border-radius: 0.8rem;

  &::placeholder {
    color: $c-gray-medium;
  }

  @include mq(m) {
    min-height: 20rem;
  }
}
